import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PriLoginService } from '../service/pri-login.service';
import { UtilityService } from './../../utilityServices/utility.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public isformValid = false;
  public error = {
    error: false,
    success: false,
    msg : ''
  };
  template =  null;
  titleText = 'Recover Password';
  subTitleText = 'Don\'t worry, we will help you'
  emailText = 'Username';
  emailError = 'Please provide username';
  buttonText = 'Email me a Recovery Link'
  constructor(
    private readonly priLoginService: PriLoginService,
    private readonly utilityService: UtilityService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }
  public forgotForm: FormGroup = new FormGroup(
    {
      email: new FormControl('', [
        Validators.required
      ])
    });

  ngOnInit() {
    this.forgotForm.valueChanges.subscribe((form) => {
      this.validateForm(form);
    });
    this.priLoginService.opcoConfig.subscribe((res:any) => {
      this.template = res.template && res.template.forgotPassword ? res.template.forgotPassword : this.template;
      this.titleText = this.template &&  this.template.titleText ? this.template.titleText : this.titleText;
      this.subTitleText = this.template &&  this.template.subTitleText ? this.template.subTitleText : this.subTitleText;
      this.emailText = this.template && this.template.emailText? this.template.emailText : this.emailText;
      this.emailError = this.template && this.template.emailText? `Please provide ${this.template.emailText}` : this.emailError;
      this.buttonText = this.template && this.template.buttonText ? this.template.buttonText : this.buttonText;
    })
  }

  public errorHandling = (control: string, error: string) => {
    return this.forgotForm.controls[control].hasError(error);
  }
  public validateForm(form): void {
    if (form.email !== '' && !this.errorHandling('email', 'email')) {
      this.isformValid = true;
     } else {
      this.isformValid = false;
     }
  }
  public submitFun(): void {
   this.priLoginService.setUserEmail(this.forgotForm.controls.email.value);
   this.priLoginService.forgotPasswordEmail(this.forgotForm.value).subscribe((res) => {
     this.router.navigate(['/forgotPasswordSuccess']);
   }, (error) => {
    this.router.navigate(['/forgotPasswordSuccess']);
   });
  }
  showError(msg, error): void {
    if (error === true) {
      this.error.error = true;
    } else {
      this.error.success = true;
    }
    this.error.msg = msg;
  }
}  