import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './priLoginComp/login/login.component';
import { ResetPasswordComponent } from './priLoginComp/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './priLoginComp/forgot-password/forgot-password.component';
import { HeaderComponent } from './sharedComp/header/header.component';
import { MaterialModule } from './material/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModule, OidcConfigService, OpenIdConfiguration, OidcSecurityService } from 'angular-auth-oidc-client';
import { DhrdTranslatorModule, DhrdTranslatorService } from 'dhrd-translator';

import { SnackBarComponent } from './sharedComp/snack-bar/snack-bar.component';
import { ResetForgotPasswordComponent } from './priLoginComp/reset-forgot-password/reset-forgot-password.component';
import { SuccessMsgComponent } from './sharedComp/success-msg/success-msg.component';
import { ForgotPasswordSuccessComponent } from './sharedComp/forgot-password-success/forgot-password-success.component';
import { EmailPageComponent } from './priLoginComp/email-page/email-page.component';
import { AuthorizeComponent } from './priLoginComp/authorize/authorize.component';
import { PriLoginService } from './priLoginComp/service/pri-login.service';
import { LoginSuccessComponent } from './sharedComp/login-success/login-success.component';
import { SpinnerInterceptorService } from './interceptor/spinner-interceptor.service';
import { ErrorInterceptorService } from './interceptor/error-interceptor.service';
import { CookieConsentComponent } from './sharedComp/cookie-consent/cookie-consent.component';
import { environment } from './../environments/environment';

export function configureAuth(oidcConfigService: OpenIdConfiguration) {
  return () =>
      oidcConfigService = {
          stsServer: 'https://asthonfederation.auth.us-east-1.amazoncognito.com',
          redirect_url: window.location.origin + '/dashboard',
          post_logout_redirect_uri: window.location.origin + '/login',
          client_id: 'v6his6rddcipbpfddoqj401ol',
          scope: 'openid profile email',
          response_type: 'code',
          max_id_token_iat_offset_allowed_in_seconds: 24 * 60 * 60
          // silentRenew: true,
          // silentRenewUrl: `${window.location.origin}`,
          // logLevel: LogLevel.Debug,
      };
}



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    HeaderComponent,
    SnackBarComponent,
    ResetForgotPasswordComponent,
    SuccessMsgComponent,
    ForgotPasswordSuccessComponent,
    EmailPageComponent,
    AuthorizeComponent,
    LoginSuccessComponent,
    CookieConsentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    AuthModule.forRoot(),
    DhrdTranslatorModule.forRoot({language: '', tenantId: '', apiBaseUrl: `${environment.lamdaUrl}get_tenant_basic_profile`})
  ],
  providers: [
    OidcConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: configureAuth,
            deps: [OidcConfigService],
            multi: true,
        },
        {provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptorService, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true},
        DhrdTranslatorService
  ],
  bootstrap: [AppComponent] ,
  entryComponents: [
    SnackBarComponent,
  ]
})
export class AppModule { 
  constructor(private priLoginComp: PriLoginService, private oidcSecurityService: OidcSecurityService) {
    this.priLoginComp.opcoConfig.subscribe(opcoConfig => {
      if(opcoConfig['userPoolDomainName']) {
        this.priLoginComp.getOIDCConfig(opcoConfig['userPoolID']).subscribe(res => {
          const config = {
            stsServer: opcoConfig['userPoolDomainName'],
              redirect_url: window.location.origin + '/dashboard',
              post_logout_redirect_uri: window.location.origin + '/login',
              client_id: opcoConfig['clientID'],
              scope: 'openid profile email',
              response_type: 'code',
              max_id_token_iat_offset_allowed_in_seconds: 24 * 60 * 60
          }
          this.oidcSecurityService.setupModule(config, res);
        })
      }
    })
  }
}
