import { Component, OnInit } from '@angular/core';
import { PriLoginService } from './../../priLoginComp/service/pri-login.service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

  constructor(private readonly priLoginService: PriLoginService) { }

  template = null;
  content = 'cookie consent';
  
  ngOnInit() {
    this.priLoginService.opcoConfig.subscribe((res:any) => {
      this.template = res.template ? res.template : this.template;
      this.content = this.template && this.template.cookieConsentText ? this.template.cookieConsentText : this.content;
    })
  }

  handleConsent(agree){
    if(agree){
      this.priLoginService.hasUserConsent.next('yes');
      sessionStorage.setItem('hasUserConsent', 'yes');
    } else {
      this.priLoginService.hasUserConsent.next('no');
      sessionStorage.setItem('hasUserConsent', 'no');
    }
  }

}
