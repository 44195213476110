import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { DhrdTranslatorService } from 'dhrd-translator';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private readonly dhrdTranslatorService: DhrdTranslatorService) { }

  public requiredValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const validValue  = control.value.trim() === '';
      return validValue  ? {required: {value: control.value}} : null;
    };
  }

    public passwordMsg(password: string, guidelines: any): string {
    const numbers = /[0-9]/g;
    const symbol = /[!$@#$%^&*()_|~=`{}\[\]:";'<>?,.\/\\+-]/g;
    const lowerCaseLetters = /[a-z]/g;
    const  upperCaseLetters = /[A-Z]/g;
    let returmsg = '';
    if (!password.match(numbers) && guidelines.requireNumbers) {
      returmsg += `${this.dhrdTranslatorService.getTranslation('numbers', 'num')}, `;
    }
    if (!password.match(lowerCaseLetters) && guidelines.requireLowercase) {
      returmsg += `${this.dhrdTranslatorService.getTranslation('lowercase', 'lower')}, `;
    }
    if (!password.match(symbol) && guidelines.requireSymbols) {
      returmsg += `${this.dhrdTranslatorService.getTranslation('symbol', 'sym')}, `;
    }
    if (!password.match(upperCaseLetters) && guidelines.requireUppercase) {
      returmsg += `${this.dhrdTranslatorService.getTranslation('uppercase', 'upper')}, `;
    }
    if (password.length < guidelines.minimumLength) {
      const msg1 =
      returmsg += `${this.dhrdTranslatorService.getTranslation('atleast', 'atleast')} ${guidelines.minimumLength} ${this.dhrdTranslatorService.getTranslation('characters', 'chars')},`;
    }
    return returmsg;
  }
}
