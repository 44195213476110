import { Component, OnInit } from '@angular/core';
import { PriLoginService } from '../service/pri-login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { UtilityService } from './../../utilityServices/utility.service';
import { DhrdTranslatorService } from 'dhrd-translator';

@Component({
  selector: 'app-reset-forgot-password',
  templateUrl: './reset-forgot-password.component.html',
  styleUrls: ['./reset-forgot-password.component.scss']
})
export class ResetForgotPasswordComponent implements OnInit {

  public isformValid = false;
  public hide2 = true;
  public hide3 = true;
  public error = {
    error: false,
    success: false,
    msg : '',
    passwordError: false
  };
  public uid = '';
  public code = '';
  isLinkValid = true;
  template = null;
  guidelines = null;
  passwordValidationMessage = '';
  titleText = 'Reset Password';
  newPasswordText = 'New Password';
  confirmPasswordText = 'Confirm Password';
  newPasswordError = 'Please provide new password'
  confirmPasswordError = 'Please confirm password';
  buttonText = 'Reset Password';
  passwordGuidelinesTitleText = 'In order to protect your account, make sure your new password :';
  linkExpirationText = 'Reset Password link has expired. Please request reset password again.';

  constructor(
    private readonly priLoginService: PriLoginService,
    private readonly route: ActivatedRoute,
    private readonly utilityService: UtilityService,
    private readonly router: Router,
    private readonly dhrdTranslatorService: DhrdTranslatorService
  ) { }
  public resetForm: FormGroup = new FormGroup(
    {
      proposedPassword: new FormControl(''),
      reEnterPassword: new FormControl('')
    });

    public passwordConfirmationValidation = (control: AbstractControl): {[key: string]: any} | null => {
      let isValid = control.value === this.resetForm.value.proposedPassword;
      this.confirmPasswordError = isValid ? `Please ${this.template && this.template.confirmPasswordText ? this.template.confirmPasswordText : 'confirm password'}` : `${this.template && this.template.confirmPasswordText ? this.template.confirmPasswordText : 'confirm password'} should be same as 
      ${this.template && this.template.newPasswordText ? this.template.newPasswordText : 'mew password'}`;
      return !isValid ? {passwordConfirmation: {value: control.value}} : null;
    };
  
    public passwordValidation = (control: AbstractControl): {[key: string]: any} | null => {
      let isValid = false;
      if(control.value !== this.resetForm.value.reEnterPassword) {
        this.resetForm.controls.reEnterPassword.setErrors({passwordConfirmation: {value: control.value}});
      } else {
        this.resetForm.controls.reEnterPassword.setErrors(null); 
      }
      const guidelinesObj = this.guidelines ? this.guidelines : {}; 
      const errMsg = this.utilityService.passwordMsg(control.value, guidelinesObj)
      if(errMsg) {
        isValid = false;
        this.passwordValidationMessage = this.createPasswordMessage(errMsg);
      } else {
        isValid = true;
      }
      return !isValid ? {password: {value: control.value}} : null;
    };

  ngOnInit() {
    this.resetForm.valueChanges.subscribe((form) => {
      // this.validateForm(form);
    });
    this.resetForm.controls.reEnterPassword.setValidators([Validators.required,this.passwordConfirmationValidation]);
    this.resetForm.controls.proposedPassword.setValidators([Validators.required, this.passwordValidation]);
    this.priLoginService.opcoConfig.subscribe((res:any) => {
      if(res['tenantId']){
        this.priLoginService.getPasswordGuidelines().subscribe( guidelinesRes => {
          this.guidelines = guidelinesRes;
        })
      }
      this.template = res.template && res.template.resetPassword ? res.template.resetPassword : this.template;
      this.titleText = this.template &&  this.template.titleText ? this.template.titleText : this.titleText;
      this.passwordGuidelinesTitleText = this.template &&  this.template.guidelinesTitleText ? this.template.guidelinesTitleText : this.passwordGuidelinesTitleText;
      this.newPasswordText = this.template && this.template.newPasswordText? this.template.newPasswordText : this.newPasswordText;
      this.confirmPasswordText = this.template && this.template.confirmPasswordText? this.template.confirmPasswordText : this.confirmPasswordText;
      this.newPasswordError = this.template && this.template.newPasswordText? `Please provide ${this.template.newPasswordText}` : this.newPasswordError;
      this.confirmPasswordError = this.template && this.template.confirmPasswordText? `Please provide ${this.template.confirmPasswordText}` : this.confirmPasswordError;
      this.buttonText = this.template && this.template.buttonText ? this.template.buttonText : this.buttonText;
      this.linkExpirationText = this.template && this.template.linkExpirationText ? this.template.linkExpirationText : this.linkExpirationText;
    })
    console.log(this.route.queryParams);
    this.route.queryParams.subscribe((par: any) => {
    this.uid =  par.id;
    this.code = par.token;
    const currentDate = new Date();
    if(par.validTill) {
      const validDate = new Date(par.validTill*1000);
      this.isLinkValid = currentDate < validDate ? true: false;
    }
    });
  }
  public validateForm(form: any): void {
    if (form.proposedPassword !== '' && form.reEnterPassword !== '') {
      if (form.proposedPassword === form.reEnterPassword) {
        this.isformValid = true;
        this.error.error = false;
        this.error.msg = '';
      } else {
        this.showError('Must match the new password entered above', true, 'error');
      }
     }
  }
  public errorHandling = (control: string, error: string) => {
    return this.resetForm.controls[control].hasError(error);
  }

  createPasswordMessage(message: string): string {
    let errormsg = this.dhrdTranslatorService.getTranslation('Please include', 'pleaseInclude') + ' ';
      const errors = message.split(',');
      errors.pop();
      const n = errors.length;
      const errorn = errors[(n - 1)];
      if (n > 1) {
        errormsg += errors.splice(0, (n - 1));
        errormsg += ` & ${errorn}`;
      } else {
        errormsg += `${errorn}`;
      }
    return errormsg;
  }

  submitFun(): void {
    this.forgotPassword();
  }

  forgotPassword(): void {
    const obj2 = {
      confirmationCode : this.code,
      username: this.uid,
      password: this.resetForm.value.proposedPassword
  };
    this.priLoginService.forgotPasswordChange(obj2).subscribe((res) => {
      let pageName = this.template && this.template.successMessage ? this.template.successMessage: 'Password Reset Sucessfully'
      pageName = this.dhrdTranslatorService.getTranslation(pageName, 'resetPasswordSuccessMessage')
     this.priLoginService.setSuccessPage(pageName);
     this.priLoginService.setUserEmail(this.uid);
     this.router.navigate(['/successfull']);
  }, (err) => {
    if (err.error.code === 'UM108') {
      const errMsg = this.dhrdTranslatorService.getTranslation('New Password is invalid. Please try again', 'passwordErrorText1');
      this.showError(errMsg, true, 'passwordError');
    } 
    else if(err.error.code === 'UM109') {
      const errMsg = this.dhrdTranslatorService.getTranslation('Please provide valid details', 'passwordErrorText2');
      this.showError(errMsg, true, 'passwordError');
    }
    else if(err.error.code === 'UM112') {
      this.showError('Token has expired. Please request reset password again', true, 'passwordError');
    } else {
      const errMsg = this.dhrdTranslatorService.getTranslation('Something went wrong. Please try again', 'genericErrorText');
      this.showError(errMsg, true, 'passwordError');
    }
  });
  }

  showError(msg, error, type?): void {
      if (error === true) {
        if (type === 'error') {
          this.error.passwordError = false;
          this.error.error = true;
        } else if (type === 'passwordError') {
          this.error.error = false;
          this.error.passwordError = true;
        }
    } else {
      this.error.success = true;
    }
      this.error.msg = msg;
  }

}
