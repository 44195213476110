import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpinnerLoaderService } from './spinner-loader.service';

@Injectable({
  providedIn: 'root'
})
export class SpinnerInterceptorService implements HttpInterceptor{

  constructor(private spinnerLoader: SpinnerLoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    this.spinnerLoader.isLoading.next(true);

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.spinnerLoader.isLoading.next(false);
        }
      }, err => {
        this.spinnerLoader.isLoading.next(false)
      })
    )
  }
}
