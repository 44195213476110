import { Component, OnInit } from '@angular/core';
import { imgUrl } from './../../../assets/imgUrl';
import { PriLoginService } from './../../priLoginComp/service/pri-login.service';


@Component({
  selector: 'app-forgot-password-success',
  templateUrl: './forgot-password-success.component.html',
  styleUrls: ['./forgot-password-success.component.scss']
})
export class ForgotPasswordSuccessComponent implements OnInit {

  public userEmail = '';
  public adminEmail = '';
  template = null;
  titleText = 'Recover Password';
  content = 'Thanks, We will verify your account and send an email with instructions to reset your password. In case you don\'t recieve any email, please contact your admin for access.';

  constructor(private readonly priLoginService: PriLoginService) { }

  ngOnInit() {
    this.priLoginService.opcoConfig.subscribe((res:any) => {
      this.template = res.template && res.template.forgotPassword ? res.template.forgotPassword : this.template;
      this.content = this.template && this.template.successMessage ? this.template.successMessage : this.content;
      this.titleText = this.template && this.template.titleText ? this.template.titleText : this.titleText;
    })
    this.priLoginService.getUserEmail().subscribe(res => {
        this.userEmail = res;
      });
    this.priLoginService.opcoConfig.subscribe(res => {
      this.adminEmail = res['adminEmail'];
    })
  }
}
