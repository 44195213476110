import { Component, OnInit } from '@angular/core';
import { OidcSecurityService} from 'angular-auth-oidc-client';
import {DhrdTranslatorService } from 'dhrd-translator';

import { imgUrl } from 'src/assets/imgUrl';
import { PriLoginService } from './priLoginComp/service/pri-login.service';
import { environment } from './../environments/environment';
import { ActivatedRoute } from '@angular/router';

import { SpinnerLoaderService } from './interceptor/spinner-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  public imglist = imgUrl;
  title = 'LS-Login-App-UI';
  opcoConfigObj: any;
  loadSpinner: boolean;
  configFeteched = false
  languageFetched = false;
  footerText = 'Life Science Digital. All right are reserved';

  constructor(private readonly priLoginService: PriLoginService,
              public readonly oidcSecurityService: OidcSecurityService,
              private readonly route: ActivatedRoute,
              private readonly loader: SpinnerLoaderService,
              private dhrdTranslatorService: DhrdTranslatorService
              ) {
                this.loader.isLoading.subscribe(loadServ=>{
                  setTimeout(() => {
                    this.loadSpinner = loadServ;
                  }, 10)
                })
              }

              ngOnInit() {
          
                this.dhrdTranslatorService.setAPIBaseUrl(`${environment.lamdaUrl}get_tenant_basic_profile`);
                this.route.queryParams.subscribe( (par:any) => {
                  console.log(par);
                  const clientName = sessionStorage.getItem('clientName');
                  if(location.search.indexOf('client=') !== -1) {
                    if (!this.configFeteched && par.client) {
                      this.configFeteched = true;
                      this.dhrdTranslatorService.setTenantId(par.client);
                      this.priLoginService.getOpcoConfiguration(par.client);
                    }
                  } else if (clientName){
                    if(!this.configFeteched){
                      this.configFeteched = true;
                      this.dhrdTranslatorService.setTenantId(clientName);
                      this.priLoginService.getOpcoConfiguration(clientName);
                    }
                  }
            
                  if(location.search.indexOf('language=') !== -1) {
                    if (!this.languageFetched && par.language) {
                      this.languageFetched = true;
                      this.dhrdTranslatorService.setLanguage(par.language);
                      sessionStorage.setItem('language', par.language);
                    }
                  } else if (sessionStorage.getItem('language')){
                    if(!this.languageFetched){
                      this.languageFetched = true;
                      this.dhrdTranslatorService.setLanguage(sessionStorage.getItem('language'))
                    }
                  } else {
                    this.dhrdTranslatorService.setLanguage(navigator.language);
                    sessionStorage.setItem('language', navigator.language);
                  }
                });
                this.priLoginService.opcoConfig.subscribe((res:any) => {
                  this.opcoConfigObj = res;
                  this.footerText = res.footer ? res.footer : this.footerText; 
                  localStorage.setItem('opcoConfig', JSON.stringify(this.opcoConfigObj));
                  this.addCustomProperty(res);
                });
                if (this.oidcSecurityService.moduleSetup) {
                  this.doCallbackLogicIfRequired();
              } else {
                  this.oidcSecurityService.onModuleSetup.subscribe(() => {
                      this.doCallbackLogicIfRequired();
                  });
              }
                this.oidcSecurityService.getIsAuthorized().subscribe(auth => {

                  if(auth) {
                    this.getAshtonToken();
                  }
                })
              }
  private getAshtonToken() {
    const reqObj = JSON.parse(sessionStorage.getItem(`authorizationResult_${this.opcoConfigObj['clientID']}`));

        this.priLoginService.getAshtonToken(reqObj).subscribe(res => {
          this.priLoginService.loginMessage.next({
            errorMessage: '',
            successMessage: 'Login successful'
          })
          localStorage.setItem('opcoConfig', JSON.stringify(this.opcoConfigObj));
          const IdToken = JSON.parse(atob(JSON.parse(atob(res.token.split('.')[1]))['IdToken'].split('.')[1]));
          const email = IdToken['email'];
          const maxAge = environment.cookieExpiryDays*24*60*60;
          if(sessionStorage.getItem('hasUserConsent') === 'yes') {
            document.cookie=`ashton_user_1=${email}; max-age=${maxAge};`;
            sessionStorage.removeItem('hasConsent');
          }
          else if(document.cookie.indexOf('ashton_user') !== -1&& document.cookie.indexOf(email) === -1){
            const cookies = document.cookie.split(';');
            const userNumber = 1 + Number(cookies[cookies.length-1].split('=')[0].split('_')[2]);
            document.cookie = `ashton_user_${userNumber}=${email}; max-age=${maxAge}; domian=${location.host}`;
          } else if(document.cookie.indexOf('ashton_user') !== -1) {
            const cookies = document.cookie.split(';');
            const isEmailUsed = cookies.find( cookie => cookie.indexOf(email) !== -1);
            document.cookie=`${isEmailUsed}; max-age=${maxAge};`;
          }
          if(this.opcoConfigObj.redirectUrl) {
            const redirectUrl = sessionStorage.getItem('redirectUrl') ? sessionStorage.getItem('redirectUrl') : this.opcoConfigObj.redirectUrl;
            sessionStorage.clear();
            window.open(`${redirectUrl}/${res['token']}`, '_self')
          }
        });
  }

  private addCustomProperty(res) {
    if(this.opcoConfigObj['cssPath']) {
      const customStyle = document.createElement('link');
        customStyle.setAttribute('href', this.opcoConfigObj['cssPath']);
        customStyle.setAttribute('rel', 'stylesheet');
        const head = document.querySelector('head');
        head.appendChild(customStyle);
    }
    if(res['tenantId']) {
      sessionStorage.setItem('tenantId', res['tenantId'])
    }
    if(res['name']) {
      sessionStorage.setItem('clientName', res['name'])
    }
    
    if(this.opcoConfigObj['logoUrl']) {
      const tabIcon = document.querySelector('link[type="image/x-icon"]');
      tabIcon.setAttribute('href', this.opcoConfigObj['logoUrl']);
    }
    if(this.opcoConfigObj['tabTitle']) {
      const tabTitle = document.querySelector('title');
      tabTitle.innerHTML = this.opcoConfigObj['tabTitle'];
    }
  }

  private doCallbackLogicIfRequired() {
    // Will do a callback, if the url has a code and state parameter.
    this.oidcSecurityService.authorizedCallbackWithCode(window.location.toString());
  }
}
