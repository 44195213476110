import { Component, OnInit } from '@angular/core';
import { imgUrl } from './../../../assets/imgUrl';
import { Router } from '@angular/router';
import { PriLoginService } from './../../priLoginComp/service/pri-login.service';

@Component({
  selector: 'app-success-msg',
  templateUrl: './success-msg.component.html',
  styleUrls: ['./success-msg.component.scss']
})
export class SuccessMsgComponent implements OnInit {
public imglist = imgUrl;
opcoConfig = {};
public pageName = 'Password Created';
  constructor( private readonly router: Router,
               private readonly priLoginService: PriLoginService) { }

  ngOnInit() {
    this.priLoginService.getSuccessPage().subscribe((page) => {
      this.pageName = page; }
    );
    this.priLoginService.opcoConfig.subscribe( config => {
      this.opcoConfig = config;
    })
  }

  goLogin() {
    console.log(this.opcoConfig['name']);
    this.router.navigateByUrl(`/login?client=${this.opcoConfig['name']}`);
  }
}
