// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// //Working for dev env
// export const environment = {
//   production: false,
//   apiBaseUrl: "https://api.ls.dev.dhr-lsdigital.com",
//   baseUrl: "https://api.ls.dev.dhr-lsdigital.com/v2/user_management",
//   resetPassword: "/change_password/",
//   login: "https://api.ls.dev.dhr-lsdigital.com/v2/user_authentication/get_token",
//   ForgotPassworLink: "/forgot_password_link/",
//   ForgotPasswor: "/reset_password",
//   passwordGuildelines: "/get_passwordguidelines",
//   redirectURl: "http://localhost:4300/landing",
//   lamdaUrl: "https://s4vkv9p47h.execute-api.eu-central-1.amazonaws.com/lsdev/um-extended-apis/",
//   authentication: "https://api.ls.dev.dhr-lsdigital.com/v2/auth_management/",
//   wellKnownEndPoint: "https://cognito-idp.eu-central-1.amazonaws.com",
//   cookieExpiryDays: 7,
// };


//https://j1b4magu71.execute-api.us-east-1.amazonaws.com/env-x

// Working for envx env 
// export const environment = {
//   production: false,
//   apiBaseUrl: "https://api-env-x.project-ashton.com/",
//   baseUrl: "https://api-env-x.project-ashton.com/v2/user_management",
//   resetPassword: "/change_password/",
//   login: "https://api-env-x.project-ashton.com/v2/user_authentication/get_token",
//   ForgotPassworLink: "/forgot_password_link/",
//   ForgotPasswor: "/reset_password",
//   passwordGuildelines: "/get_passwordguidelines",
//   redirectURl: "http://localhost:4300/landing",
//   lamdaUrl: "https://6i1c6z1yv0.execute-api.eu-central-1.amazonaws.com/lsdev/um-extended-apis/",
//   authentication: "https://api-env-x.project-ashton.com/v2/auth_management/",
//   wellKnownEndPoint: "https://cognito-idp.eu-central-1.amazonaws.com",
//   cookieExpiryDays: 7,
// };

//QA env
export const environment = {
  production: false,
  apiBaseUrl: "https://api.ls.qa.dhr-lsdigital.com/",
  baseUrl: "https://api.ls.qa.dhr-lsdigital.com/v2/user_management",
  resetPassword: "/change_password/",
  login: "https://api.ls.qa.dhr-lsdigital.com/v2/user_authentication/get_token",
  ForgotPassworLink: "/forgot_password_link/",
  ForgotPasswor: "/reset_password",
  passwordGuildelines: "/get_passwordguidelines",
  redirectURl: "http://localhost:4300/landing",
  lamdaUrl: "https://s4vkv9p47h.execute-api.eu-central-1.amazonaws.com/lsdev/um-extended-apis/",
  authentication: "https://api.ls.qa.dhr-lsdigital.com/v2/auth_management/",
  wellKnownEndPoint: "https://cognito-idp.eu-central-1.amazonaws.com",
  cookieExpiryDays: 7,
};

// export const environment = {
//   production: false,
//   apiBaseUrl: "https://rxxvc8he81.execute-api.eu-central-1.amazonaws.com/ls-dev-eks-bastion-spog-tenant",
//   baseUrl: "https://rxxvc8he81.execute-api.eu-central-1.amazonaws.com/ls-dev-eks-bastion-spog-tenant/v2/user_management",
//   resetPassword: "/change_password/",
//   login: "https://rxxvc8he81.execute-api.eu-central-1.amazonaws.com/ls-dev-eks-bastion-spog-tenant/v2/user_authentication/get_token",
//   ForgotPassworLink: "/forgot_password_link/",
//   ForgotPasswor: "/reset_password",
//   passwordGuildelines: "/get_passwordguidelines",
//   redirectURl: "http://localhost:4300/landing",
//   lamdaUrl: "https://057afdjmc8.execute-api.eu-central-1.amazonaws.com/spogdev/um-extended-apis/",
//   authentication: "https://rxxvc8he81.execute-api.eu-central-1.amazonaws.com/ls-dev-eks-bastion-spog-tenant/v2/auth_management/",
//   wellKnownEndPoint: "https://cognito-idp.eu-central-1.amazonaws.com",
//   cookieExpiryDays: 7,
// };




// export const environment = {
//   production: false,
//   baseUrl: 'https://api-env-x.project-ashton.com/v1/user_management',
//   resetPassword: '/change_password/',
//  login: 'https://api-env-x.project-ashton.com/v1/user_authentication/get_token',
//  ForgotPassworLink : '/forgot_password_link/',
//  ForgotPasswor: '/reset_password',
//  redirectURl: 'http://localhost:4300/Admin/'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
