import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from './../../../environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { env } from 'process';

@Injectable({
  providedIn: 'root'
})
export class PriLoginService {
  private readonly successPage: BehaviorSubject<string> = new BehaviorSubject('Password Created Successfully');
  private readonly userEmail: BehaviorSubject<string> = new BehaviorSubject('');
  private readonly userIdp: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly IDPList = new BehaviorSubject([]);
  public readonly opcoConfig: BehaviorSubject<object> = new BehaviorSubject({
    logoUrl: '',
    title: 'Welcome to Life Science Digital',
    footer: 'Life Science Digital. All right are reserved',
    redirectUrl: '',
    tenantId: '',
    cssPath: '',
    adminEmail: 'admin@dhr.com',
    name: '',
    clientID: '',
    userPoolDomainName: '',
    userPoolID: ''
  });
  public hasUserConsent: BehaviorSubject<string> = new BehaviorSubject('')
  public loginMessage: BehaviorSubject<object> = new BehaviorSubject({
    errorMessage: '',
    successMessage: ''
  })
  private readonly contentType = 'application/json'
  private readonly headers = new HttpHeaders().set('Content-Type',this.contentType);
  private readonly requestOptions = {
    headers: this.headers,
    withCredentials: false
  };
  constructor(private readonly httpClient: HttpClient) { }

  getSuccessPage(): Observable<string> {
    return this.successPage.asObservable();
  }

  setSuccessPage(obj: string) {
    this.successPage.next(obj);
  }

  setUserEmail(email: string) {
    this.userEmail.next(email);
  }

  getUserEmail(): Observable<string> {
    return this.userEmail.asObservable();
  }

  setIdp(idp: string) {
    this.userIdp.next(idp);
  }

  getIdp(): Observable<string> {
    return this.userIdp.asObservable();
  }


  public resetPassword(obj: any,uid: string): Observable<any> {
    const headerObj = {
      'Content-Type': this.contentType,
      'tenantId': this.opcoConfig.value['tenantId']
    }
    const requestOptions = {
      headers: new HttpHeaders(headerObj),
      withCredentials: false
    };
    const url = `${environment.baseUrl}${environment.resetPassword}${uid}`;
    return this.httpClient.patch(url,obj,requestOptions);
  }
  public login(obj: any): Observable<any> {
    const headerObj = {
      'Content-Type': this.contentType,
      'tenantId': this.opcoConfig.value['tenantId']
    }
    const requestOptions = {
      headers: new HttpHeaders(headerObj),
      withCredentials: false
    };
    const url = `${environment.login}`;
    return this.httpClient.post(url,obj,requestOptions);
  }
  public forgotPasswordEmail(obj: any): Observable<any> {
    const headerObj = {
      'Content-Type': this.contentType,
      'tenantId': this.opcoConfig.value['tenantId']
    }
    const requestOptions = {
      headers: new HttpHeaders(headerObj),
      withCredentials: false
    };
    const url = `${environment.baseUrl}${environment.ForgotPassworLink}${obj.email}`;
    return this.httpClient.patch(url,{},requestOptions);
  }
  public forgotPasswordChange(obj: any): Observable<any> {
    const headerObj = {
      'Content-Type': this.contentType,
      'tenantId': this.opcoConfig.value['tenantId']
    }
    const requestOptions = {
      headers: new HttpHeaders(headerObj),
      withCredentials: false
    };
    const url = `${environment.baseUrl}${environment.ForgotPasswor}`;
    return this.httpClient.patch(url,obj,requestOptions);
  }

  getPasswordGuidelines(): Observable<any> {
    const headerObj = {
      'Content-Type': this.contentType,
      'tenantId': this.opcoConfig.value['tenantId']
    }
    const requestOptions = {
      headers: new HttpHeaders(headerObj),
      withCredentials: false
    };
    const url = `${environment.baseUrl}${environment.passwordGuildelines}`;
    return this.httpClient.get(url,requestOptions);
  }

  public getPrivacyPolicyLink(): Observable<any> {
    const url = `${environment.baseUrl}${environment.ForgotPasswor}`;
    return this.httpClient.get(url,this.requestOptions);
  }

  public getOpcoConfiguration(opco): void {
    const headers = new HttpHeaders(
      {
        'Content-Type': this.contentType,
        'operation': 'get_tenant_basic_profile',
      }
    );
    this.httpClient.get(`${environment.lamdaUrl}get_tenant_basic_profile?name=${opco}`,{ headers }).subscribe(res => {
      this.opcoConfig.next(res);
    })
  }

  public updateIDPList(list): void {
    this.IDPList.next(list);
  }

  public getIDPList(username): Promise<any> {
    console.log(this.opcoConfig.value['tenantId']);
    console.log({ username });
    const headers = new HttpHeaders(
      {
               'tenantid': this.opcoConfig.value['tenantId']
      }
    );
    // const headerObj = {
    //   "tenantId": "4cc54b86-6018-6a33-da08-cf6b900f3200"
    // }
    // const requestOptions = {
    //   headers: new HttpHeaders(headerObj),
    //   withCredentials: false
    // };
     return this.httpClient.post(`${environment.baseUrl}/get_usertype`, {username}, {headers}).toPromise()
   // return this.httpClient.post(`https://08g867ea47.execute-api.eu-central-1.amazonaws.com/dev/v1/device-data-management/get-usertype-envx`,{ username },requestOptions).toPromise()     //dev env
   // return this.httpClient.post(`https://woeab86f3e.execute-api.eu-central-1.amazonaws.com/envx-dev/v1/device-data-management/get-usertype-envx`,{ username },requestOptions).toPromise()   //envx env
   //return this.httpClient.post(`https://l9pxrboasc.execute-api.eu-central-1.amazonaws.com/dev/v1/device-data-management/get-usertype-envx`,{ username },requestOptions).toPromise()     //qa env

   
  }

  public getAshtonToken(reqObj): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': this.contentType,
      'tenantId': sessionStorage.getItem('tenantId')
    });
    const requestOptions = {
      headers,
      withCredentials: false
    };
    return this.httpClient.post(`${environment.authentication}token_exchange`,reqObj,requestOptions)
  }

  public getOIDCConfig(domainId): Observable<any> {
    return this.httpClient.get(`https://cognito-idp.${domainId.split('_')[0]}.amazonaws.com/${domainId}/.well-known/openid-configuration`)
  }

  public resendInvitationLink(obj: any): Observable<any> {
    const headerObj = {
      'Content-Type': 'application/json',
      'tenantId': this.opcoConfig.value['tenantId']
    }
    const requestOptions = {
      headers: new HttpHeaders(headerObj),
      withCredentials: false
    };
    return this.httpClient.post(`${environment.baseUrl}/invite_link_expiry`,obj,requestOptions);
  }
}
