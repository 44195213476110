import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './priLoginComp/login/login.component';
import { ResetPasswordComponent } from './priLoginComp/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './priLoginComp/forgot-password/forgot-password.component';
import { ResetForgotPasswordComponent } from './priLoginComp/reset-forgot-password/reset-forgot-password.component';
import { SuccessMsgComponent } from './sharedComp/success-msg/success-msg.component';
import { ForgotPasswordSuccessComponent } from './sharedComp/forgot-password-success/forgot-password-success.component';
import { EmailPageComponent } from './priLoginComp/email-page/email-page.component';
import { LoginSuccessComponent } from './sharedComp/login-success/login-success.component';
import { AuthorizeComponent } from './priLoginComp/authorize/authorize.component';

const routes: Routes = [
  {
    path: '',
  component: AuthorizeComponent
  },
  {
    path: 'login',
  component: EmailPageComponent
  },
  {
    path: 'password',
    component: LoginComponent
  },
  {path: 'forgot',
  component: ForgotPasswordComponent
  },
  {path: 'changePassword',
  component: ResetPasswordComponent
  },
  {path: 'resetPassword',
  component: ResetForgotPasswordComponent
  },
  {path: 'successfull',
  component: SuccessMsgComponent
  },
  {
    path: 'forgotPasswordSuccess',
    component: ForgotPasswordSuccessComponent
  },
  {
    path: 'dashboard',
    component: LoginSuccessComponent
  },
  {
    path: '**',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
