import { Component, OnInit } from '@angular/core';
import { imgUrl } from './../../../assets/imgUrl';
import { PriLoginService } from './../../priLoginComp/service/pri-login.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  public imglist = imgUrl;
  public OpCoConfigObj: any;
  constructor(private readonly priloginSerivice : PriLoginService) { }

  ngOnInit() {
    setTimeout(() => {
      this.priloginSerivice.opcoConfig.subscribe(res => {
        this.OpCoConfigObj = res;
      });
    }, 1000);
  }

}
