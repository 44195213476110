import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PriLoginService } from '../service/pri-login.service';
import {UtilityService} from '../../utilityServices/utility.service';
import { Router, ActivatedRoute } from '@angular/router';

import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loadSpinner = false;
  public isformValid = false;
  public hide3 = true;
  public loginAPIHits = 5;
  passwordPage = false;
  public error = {
    error: false,
    success: false,
    msg : ''
  };
  template =  null;
  titleText = 'LogIn';
  emailText = 'Username';
  passwordText =  'Password'
  emailError = 'Please provide username';
  passwordError = 'Please provide password'
  buttonText = 'Login'
  forgotPasswordLink = "Forgot Password"
  public fectchUrl: any;
  constructor(
    private readonly priLoginService: PriLoginService,
    private readonly utilityService: UtilityService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }
  public loginForm: FormGroup;

  ngOnInit() {
    this.loginForm = new FormGroup(
      {
        email: new FormControl('', [this.utilityService.requiredValidator()]),
        password: new FormControl('', [this.utilityService.requiredValidator()])
      });
    this.loginForm.valueChanges.subscribe((form) => {
      this.validateForm(form);
    });
    this.priLoginService.getUserEmail().subscribe(res => {
      this.loginForm.controls.email.setValue(res);
    })
    this.priLoginService.opcoConfig.subscribe((res:any) => {
      this.template = res.template && res.template.login ? res.template.login : this.template;
      this.titleText = this.template &&  this.template.titleText ? this.template.titleText : this.titleText;
      this.emailText = this.template && this.template.emailText? this.template.emailText : this.emailText;
      this.emailError = this.template && this.template.emailText? `Please provide ${this.template.emailText}` : this.emailError;
      this.passwordText = this.template && this.template.passwordText? this.template.passwordText : this.passwordText;
      this.passwordError = this.template && this.template.passwordText? `Please provide ${this.template.passwordText}` : this.passwordError;
      this.buttonText = this.template && this.template.buttonText2 ? this.template.buttonText2 : this.buttonText;
      this.forgotPasswordLink = this.template && this.template.forgotPasswordText ? this.template.forgotPasswordText : this.forgotPasswordLink;
    })
  }
  public errorHandling = (control: string, error: string) => {
    return this.loginForm.controls[control].hasError(error);
  }
  public validateForm(form): void {
    if (form.email.trim() !== '' && form.password.trim() !== '') {
      if (!this.errorHandling('email', 'email')) {
        this.isformValid = true;
      }
     }
  }
  public submitFun(): void {
   this.loadSpinner = true;
   this.error.error = false;
   this.priLoginService.login(this.loginForm.value).subscribe((res) => {
     const maxAge = environment.cookieExpiryDays*24*60*60;
     if(this.priLoginService.hasUserConsent.value === 'yes') {
      document.cookie=`ashton_user_1=${this.loginForm.value.email}; max-age=${maxAge};`;
    }
    else if(document.cookie.indexOf('ashton_user') !== -1 && document.cookie.indexOf(this.loginForm.value.email) === -1){
      const cookies = document.cookie.split(';');
      const userNumber = 1 + Number(cookies[cookies.length-1].split('=')[0].split('_')[2]);
      document.cookie = `ashton_user_${userNumber}=${this.loginForm.value.email}; max-age=${maxAge}; domian=${location.host}`;
    } else if(document.cookie.indexOf('ashton_user') !== -1) {
      const cookies = document.cookie.split(';');
      const isEmailUsed = cookies.find( cookie => cookie.indexOf(this.loginForm.value.email) !== -1);
      document.cookie=`${isEmailUsed.split('=')[0]}=${this.loginForm.value.email}; max-age=${maxAge};`;
    }
     this.fectchUrl = JSON.parse(localStorage.getItem('opcoConfig'));
     const redirectUrl = sessionStorage.getItem('redirectUrl') ? sessionStorage.getItem('redirectUrl') : this.fectchUrl.redirectUrl;   
      window.open(`${redirectUrl.iotReferenceAppUrl}/${res.token}`, '_self');
   }, (error) => {
    this.errorFunc();
   });
  }
 public errorFunc() {
    this.loadSpinner = false;
    this.loginAPIHits--;
    if (this.loginAPIHits < 1) {
     this.router.navigate(['/forgot']);
    }
    this.error.error = true;
    this.error.msg = `Email Id or Password was incorrect.`;
  }
}
