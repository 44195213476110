import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable, throwError  } from 'rxjs';
import { PriLoginService } from './../priLoginComp/service/pri-login.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService {

  constructor(private readonly priLoginService: PriLoginService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if(error.url.indexOf('/token') !== -1) {
          this.priLoginService.loginMessage.next({
            successMessage: '',
            errorMessage: 'Login failed. Please try again'
          })
        }
        return throwError(error);
      })
    )

  }
}
