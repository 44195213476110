import { Component, OnInit } from '@angular/core';

import { PriLoginService } from './../../priLoginComp/service/pri-login.service';

@Component({
  selector: 'app-login-success',
  templateUrl: './login-success.component.html',
  styleUrls: ['./login-success.component.scss']
})
export class LoginSuccessComponent implements OnInit {

  constructor(private readonly priLoginService: PriLoginService) { }

  opcoConfig: any;

  loginMessage = {
    errorMessage: '',
    successMessage: ''
  };

  ngOnInit(): void {
    this.priLoginService.loginMessage.subscribe((res: any) => {
      this.loginMessage = res;
    })
  }

}
