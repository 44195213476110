import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { PriLoginService } from "../service/pri-login.service";
import { UtilityService } from "../../utilityServices/utility.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-email-page",
  templateUrl: "./email-page.component.html",
  styleUrls: ["./email-page.component.scss"],
})
export class EmailPageComponent implements OnInit {
  public loadSpinner = false;
  public isformValid = false;
  public hide3 = true;
  public error = {
    error: false,
    success: false,
    msg: "",
  };
  showIDPList = false;
  public IDPList = [];
  showCookieNotification = true;
  newEmail = false;
  showEmailList = false;
  emailList = [];
  redirectMismatch = false;
  opcoCongig = null;
  template = null;
  titleText = "LogIn";
  emailText = "Username";
  emailError = "Please provide username";
  buttonText = "Next";
  disableSubmitBtn = true;

  constructor(
    private readonly priLoginService: PriLoginService,
    private readonly utilityService: UtilityService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly oidcSecurityService: OidcSecurityService
  ) {
    //ocument.cookie=`ashton_user_1=${email}; max-age=${maxAge};`;
  }
  public loginForm: FormGroup = new FormGroup({
    email: new FormControl("", [
      this.utilityService.requiredValidator(),
      Validators.email,
      Validators.required,
    ]),
    selectedIDP: new FormControl("", [
      this.utilityService.requiredValidator(),
      Validators.required,
    ]),
  });

  ngOnInit() {
    if (document.cookie.indexOf("ashton_user") !== -1) {
      this.showCookieNotification = false;
      this.showEmailList = true;
      this.emailList = document.cookie.split(";").reduce((prev, curr) => {
        if (curr.indexOf("ashton_user") !== -1) {
          let tempArray = [...prev];
          tempArray.push(curr.split("=")[1]);
          return tempArray;
        }
        return prev;
      }, []);
    } else {
      this.priLoginService.hasUserConsent.subscribe((res) => {
        if (res === "yes" || res === "no") {
          this.showCookieNotification = false;
        }
      });
    }
    this.loginForm.valueChanges.subscribe((form) => {
      this.validateForm(form);
    });
    this.priLoginService.getUserEmail().subscribe((res) => {
      this.loginForm.controls.email.setValue(res);
	  this.checkFormValidity();
    });
    this.priLoginService.opcoConfig.subscribe((res: any) => {
      this.template =
        res.template && res.template.login ? res.template.login : this.template;
      this.titleText =
        this.template && this.template.titleText
          ? this.template.titleText
          : this.titleText;
      this.emailText =
        this.template && this.template.emailText
          ? this.template.emailText
          : this.emailText;
      this.emailError =
        this.template && this.template.emailText
          ? `Please provide ${this.template.emailText}`
          : this.emailError;
      this.buttonText =
        this.template && this.template.buttonText1
          ? this.template.buttonText1
          : this.buttonText;
    });
    this.route.queryParams.subscribe((params) => {
      if (params.username) {
        this.loginForm.controls.email.setValue(params.username);
        this.priLoginService.setUserEmail(params.username);
        if (!params.redirectUrl) {
          this.priLoginService.opcoConfig.subscribe((res) => {
            if (res["userPoolDomainName"]) {
              this.submitFun();
            }
          });
        }
      }
      if (params.redirectUrl) {
        this.priLoginService.opcoConfig.subscribe((res) => {
          if (
            res["userPoolDomainName"] &&
            res["redirectUrls"].indexOf(params.redirectUrl) !== -1
          ) {
            sessionStorage.setItem("redirectUrl", params.redirectUrl);
          } else if (res["userPoolDomainName"]) {
            this.redirectMismatch = true;
          }
          if (
            res["userPoolDomainName"] &&
            params.username &&
            !this.redirectMismatch
          ) {
            this.submitFun();
          }
        });
      }
    });
    this.loginForm.valueChanges.subscribe(() => {
		this.checkFormValidity();
	});
  }
  private checkFormValidity() {
    if (this.loginForm.controls["email"].status.toLowerCase() === "valid") {
      this.disableSubmitBtn = false;
    } else if (this.loginForm.status.toLowerCase() === "invalid") {
      this.disableSubmitBtn = true;
    } else if (this.loginForm.status.toLowerCase() === "valid") {
      this.disableSubmitBtn = false;
    }
  }
  public errorHandling = (control: string, error: string) => {
    return this.loginForm.controls[control].hasError(error);
  };
  public validateForm(form): void {
    if (form.email.trim() !== "") {
      if (!this.errorHandling("email", "email")) {
        this.isformValid = true;
      }
    }
  }

  public handleEmailSelection(value) {
    this.loginForm.controls.email.setValue(value);
    this.showIDPList = false;
    this.showEmailList = false;
    setTimeout(() => {
      this.route.queryParams.subscribe((par: any) => {
        if (par) {
          this.router.navigate(["/login"], {
            queryParams: { client: par.client },
          });
        }
      });
    }, 200);
  }

  public handleNewEmail() {
    this.showEmailList = false;
    this.newEmail = true;
    this.isformValid = false;
    this.showIDPList = false;
    this.loginForm.controls.email.setValue("");
    this.loginForm.controls.selectedIDP.setValue("");
  }

  public submitFun(): void {
    this.disableSubmitBtn = true;
    this.priLoginService.setUserEmail(this.loginForm.value.email);
    this.priLoginService.opcoConfig.subscribe(
      async (config) => {
        if (config["tenantId"]) {
          if (!this.showIDPList) {
            const userTypeInfo = await this.priLoginService.getIDPList(
              this.loginForm.value.email
            );
            console.log(userTypeInfo);
            try {
              if (userTypeInfo.userType.toLowerCase() === "native") {
                this.router.navigate(["password"]);
              } else {
                const providersArry = userTypeInfo.provider.split(",");
                if (providersArry.length > 1) {
                  this.showIDPList = true;
                  this.IDPList = this.setIdpList(providersArry);
                  this.disableSubmitBtn = true;
                } else {
                  this.showIDPList = false;
                  this.oidcSecurityService.setCustomRequestParameters({
                    identity_provider: providersArry[0],
                  });
                  this.oidcSecurityService.authorize();
                }
              }
            } catch {
              const client = sessionStorage.getItem("clientName").toLowerCase();
              if (client.indexOf("videojet") !== -1) {
                this.router.navigate(["password"]);
              }
            }
          } else {
            this.checkAuthorization();
          }
        }
      },
      (err) => {
        this.disableSubmitBtn = false;
      }
    );
  }

  checkAuthorization(): void {
    console.log("caaled");
    console.log(this.loginForm);
    if (this.loginForm.status.toLowerCase() === "valid") {
      this.oidcSecurityService.setCustomRequestParameters({
        identity_provider: this.loginForm.controls["selectedIDP"].value,
      });
      this.oidcSecurityService.authorize();
    }
  }

  setIdpValue(val): void {
    this.loginForm.get("selectedIDP").setValue(val);
  }

  setIdpList(list): any {
    const idpObj = [];
    list.forEach((element) => {
      idpObj.push({ providerName: element });
    });
    return idpObj;
  }
}
