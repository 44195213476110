import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DhrdTranslatorService } from 'dhrd-translator';
import { PriLoginService } from '../service/pri-login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from './../../utilityServices/utility.service';
import { imgUrl } from './../../../assets/imgUrl';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public isformValid = false;
  public staticPrivacyURL = imgUrl.opcoPolicy;
  public hide1 = true;
  public hide2 = true;
  public hide3 = true;
  public error = {
    inlineerror: false,
    success: false,
    msg : '',
    passwordError: false
  };
  public uid = '';
  public previousPass = '';
  isLinkValid = true;
  template = null;
  guidelines = null;
  passwordValidationMessage = '';
  passwordGuidelinesTitleText = 'In order to protect your account, make sure your new password :'
  titleText = 'Change Password';
  currentPasswordText = 'Current Password';
  newPasswordText = 'New Password';
  confirmPasswordText = 'Confirm Password';
  newPasswordError = 'Please provide new password'
  currentPasswordError = 'Please provide current password';
  confirmPasswordError = 'Please confirm password';
  buttonText = 'Change Password';
  guidelinesTitleText = 'In order to protect your account, make sure your new password :';
  linkExpirationText = 'Invitation link has expired. We will send a new invitation link shortly. Please use the new invitation link. If you don\'t recieve a new inviation link, please contact admin';

  constructor(private readonly priLoginService: PriLoginService,
              private readonly utilityService: UtilityService,
              private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly dhrdTranslatorService: DhrdTranslatorService
              ) {
              }
  public resetForm: FormGroup = new FormGroup(
    {
      previousPassword: new FormControl(''),
      proposedPassword: new FormControl(''),
      reEnterPassword: new FormControl('')
    });

    public passwordConfirmationValidation = (control: AbstractControl): {[key: string]: any} | null => {
      let isValid = control.value === this.resetForm.value.proposedPassword;
      this.confirmPasswordError = isValid ? `Please ${this.template && this.template.confirmPasswordText ? this.template.confirmPasswordText : 'confirm password'}` : `${this.template && this.template.confirmPasswordText ? this.template.confirmPasswordText : 'confirm password'} should be same as 
      ${this.template && this.template.newPasswordText ? this.template.newPasswordText : 'mew password'}`;
      return !isValid ? {passwordConfirmation: {value: control.value}} : null;
    };
  
    currentPasswordValidation = (control: AbstractControl): {[key: string]: any} | null => {
      let isValid = control.value.trim() ?  true: false;
      return !isValid ? {currentPassword: {value: control.value}} : null;
    }
  
    public passwordValidation = (control: AbstractControl): {[key: string]: any} | null => {
      let isValid = false;
      if(control.value !== this.resetForm.value.reEnterPassword) {
        this.resetForm.controls.reEnterPassword.setErrors({passwordConfirmation: {value: control.value}});
      } else{
        this.resetForm.controls.reEnterPassword.setErrors(null);
      }
      let guidelinesObj = this.guidelines ? this.guidelines : {}; 
      let errMsg = this.utilityService.passwordMsg(control.value, guidelinesObj) 
      if (!errMsg) {
        if(!this.previousPass && control.value === this.resetForm.value.previousPassword) {
          isValid = false
          let msg = `${this.template && this.template.newPasswordText? this.template.newPasswordText : 'New password'} should not be same as ${this.template && this.template.currentPasswordText ? this.template.currentPasswordText : 'current password'}`
          this.passwordValidationMessage = this.dhrdTranslatorService.getTranslation(msg, 'passwordErrorText5')
        } else {
          isValid = true
        }
      } else {
        this.passwordValidationMessage = this.createPasswordMessage(errMsg);
      }
      return !isValid ? {password: {value: control.value}} : null;
    };

  ngOnInit() {
    this.resetForm.valueChanges.subscribe((form) => {
      // this.validateForm(form);
    });
    this.resetForm.controls.reEnterPassword.setValidators([this.passwordConfirmationValidation, Validators.required]);
    this.resetForm.controls.proposedPassword.setValidators([this.passwordValidation, Validators.required]);
    this.resetForm.controls.previousPassword.setValidators(this.currentPasswordValidation);
    this.priLoginService.opcoConfig.subscribe((res:any) => {
      if(res['tenantId']){
        this.priLoginService.getPasswordGuidelines().subscribe( guidelinesRes => {
          this.guidelines = guidelinesRes;
        })
        if(!this.isLinkValid) {
          const reqObj = {
            userIdentity: this.uid
          }
          this.priLoginService.resendInvitationLink(reqObj).subscribe(res => {
    
          })
        }
      }
      this.template = res.template && res.template.changePassword ? res.template.changePassword : this.template;
      this.titleText = this.template &&  this.template.titleText ? this.template.titleText : this.titleText;
      this.passwordGuidelinesTitleText = this.template &&  this.template.guidelinesTitleText ? this.template.guidelinesTitleText : this.passwordGuidelinesTitleText;
      this.currentPasswordText = this.template && this.template.currentPasswordText? this.template.currentPasswordText : this.currentPasswordText;
      this.newPasswordText = this.template && this.template.newPasswordText? this.template.newPasswordText : this.newPasswordText;
      this.confirmPasswordText = this.template && this.template.confirmPasswordText? this.template.confirmPasswordText : this.confirmPasswordText;
      this.currentPasswordError = this.template && this.template.currentPasswordText? `Please provide ${this.template.currentPasswordText}` : this.currentPasswordError;
      this.newPasswordError = this.template && this.template.newPasswordText? `Please provide ${this.template.newPasswordText}` : this.newPasswordError;
      this.confirmPasswordError = this.template && this.template.confirmPasswordText? `Please provide ${this.template.confirmPasswordText}` : this.confirmPasswordError;
      this.buttonText = this.template && this.template.buttonText ? this.template.buttonText : this.buttonText;
      this.linkExpirationText = this.template && this.template.linkExpirationText ? this.template.linkExpirationText : this.linkExpirationText;
    })
    this.route.queryParams.subscribe((par: any) => {
    this.uid =  par.id;
    this.previousPass = par.token;
    this.titleText = this.previousPass ? 'Create Password' : 'Change Password';
    this.buttonText = this.previousPass ? 'Create Password' : 'Change Password';
    if(par.token) {
      this.resetForm.controls.previousPassword.setValue(par.token);
    }
    const currentDate = new Date();
    if(par.validTill) {
      const validDate = new Date(par.validTill*1000);
      this.isLinkValid = currentDate < validDate ? true: false;
    }
    });
  }

  public getPrivacyPolicy(): void {
    this.priLoginService.getPrivacyPolicyLink().subscribe((res) => {
      this.staticPrivacyURL = res;
    });
  }

  createPasswordMessage(message: string): string {
    let errormsg = this.dhrdTranslatorService.getTranslation('Please include', 'pleaseInclude') + ' ';
      const errors = message.split(',');
      errors.pop();
      const n = errors.length;
      const errorn = errors[(n - 1)];
      if (n > 1) {
        errormsg += errors.splice(0, (n - 1));
        errormsg += ` & ${errorn}`;
      } else {
        errormsg += `${errorn}`;
      }
    return errormsg;
  }

  public validateForm(form: any): void {
    if (form.proposedPassword !== '' && form.reEnterPassword !== '') {
      if (form.proposedPassword === form.reEnterPassword) {
        // New Password is invalid. Please use at least 8 characters with a mix of uppercase & lowercase letters, numbers and symbols
          this.isformValid = true;
          this.error.inlineerror = false;
          this.error.msg = '';
      } else {
        this.showError('Must match the new password entered above', true, 'error');
        this.isformValid = false;
      }
     } else {
      this.isformValid = false;
     }

     if(form.proposedPassword === form.previousPassword && !this.previousPass) {
       this.showError('New password should not be same as current password', true, 'passwordError');
     }
  }
  public errorHandling = (control: string, error: string) => {
    return this.resetForm.controls[control].hasError(error);
  }
  submitFun(): void {
    this.firstTimePasswordChange();
  }
  firstTimePasswordChange(): void {
    const ppsPassword = this.resetForm.value.proposedPassword;
    const obj = {
      previousPassword: this.resetForm.controls.previousPassword.value,
      proposedPassword: ppsPassword
      };
       this.resetPasswordAPI(obj);
  }

  public resetPasswordAPI(obj: any): void {
    this.priLoginService.resetPassword(obj, this.uid).subscribe((res) => {
      let pageName = this.previousPass ? 'Password Created Successfully' : 'Password Changed Successfully' 
      pageName = this.template && this.template.successMessage ? this.template.successMessage: pageName;
      pageName = this.dhrdTranslatorService.getTranslation(pageName, 'changePasswordSuccessMessage'); 
      this.priLoginService.setSuccessPage(pageName);
      this.router.navigate(['/successfull']);
       }, (error) => {
        if (error.error.code === 'UM108') {
          const errMsg = this.dhrdTranslatorService.getTranslation('New Password is invalid. Please try again', 'passwordErrorText1');
          this.showError(errMsg, true, 'passwordError');
        } 
        else if(error.error.code === 'UM109') {
          const errMsg = this.dhrdTranslatorService.getTranslation('Please provide valid details', 'passwordErrorText2');
          this.showError(errMsg, true, 'passwordError');
        }
        else if(error.error.code === 'UM112') {
          this.showError('Token has expired. Please contact admin', true, 'passwordError');
        } else {
          const errMsg = this.dhrdTranslatorService.getTranslation('Something went wrong. Please try again', 'genericErrorText');
          this.showError(errMsg, true, 'passwordError');
        }
      });
  }
  showError(msg: string, error: boolean, type: string): void {
    if (error === true) {
      if (type === 'error') {
        this.error.passwordError = false;
        this.error.inlineerror = true;
      } else if (type === 'passwordError') {
        this.error.inlineerror = false;
        this.error.passwordError = true;
      }
    } else {
      this.error.success = true;
    }
    this.error.msg = msg;
  }
}
